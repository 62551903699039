<template>
  <modal
    name="gift"
    :adaptive="true"
    :width="'90%'"
    :height="'auto'"
    :maxWidth="780"
    :scrollable="true"
    :transition="'ease-in'"
    v-scroll-lock="true"
  >
    <div class="modal">
      <div class="close" @click="$modal.hide('gift')">
        <inline-svg
          :src="require('@/assets/svg/close.svg')"
          height="16"
          width="16"
          aria-label="close"
        />
      </div>
      <div class="title">Hadiah Pernikahan</div>
      <p class="text">
        Untuk melengkapi kebahagiaan Friska & Agung, Bapak/Ibu/Saudara/i dapat
        mengirimkan hadiah melalui dompet digital berikut ini:
      </p>
      <div>
        <p class="text">
          <strong>
            Bank BCA <br />
            a.n. {{ accountName }} <br />
            {{ accountNumber }}
          </strong>
        </p>
        <p class="text" @click.stop="copyToClipboard()">
          <u>Salin No. Rekening</u>
        </p>
      </div>
      <p class="text">atau dengan scan QR berikut ini</p>
      <div class="qr">
        <img :src="qr" alt="qr" />
      </div>
    </div>
  </modal>
</template>

<script>
const source = [
  "KELUARGA_AGUNG",
  "UNDANGAN_AGUNG",
  "UNDANGAN_AGUNG_ETC",
  "KELUARGA_FRISKA",
  "UNDANGAN_FRISKA",
];
const accountNumber = ["1370062681", "1370096895"];
const accountName = ["Agung Setyawan", "Friska Devina"];
const qr = [
  require("@/assets/images/QR.jpeg"),
  require("@/assets/images/QR2.png"),
];

export default {
  name: "ModalGift",
  props: {
    source: {
      type: String,
      default: "",
    },
  },
  computed: {
    map() {
      return this.source === source[0] ||
        this.source === source[1] ||
        this.source === source[2]
        ? 0
        : this.source === source[3] || this.source === source[4]
        ? 1
        : 1;
    },
    accountNumber() {
      return accountNumber[this.map];
    },
    accountName() {
      return accountName[this.map];
    },
    qr() {
      return qr[this.map];
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.accountNumber).then(
        () => {
          /* clipboard successfully set */
          this.$gtag.event("copy_account_number");
          this.$toasted.global.mwSuccess({ message: "No. Rekening Tersalin" });
        },
        () => {
          /* clipboard write failed */
          this.$toasted.global.mwError({
            message: "Gagal menyalin No. Rekening",
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  color: $primary-color;
  padding: 2rem;
  text-align: center;
  .close {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .qr {
    width: 100%;
    min-height: 225px;
    margin: auto;
    @include device(desktop) {
      max-width: 300px;
      min-height: 300px;
    }
    img {
      width: 100%;
    }
  }
}
</style>
