export default [
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978162/Prewedding/gallery/DRA_7089_s4flpx.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026830/Prewedding/gallery/thumbnail/DRA_7089_rxwk2h.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978163/Prewedding/gallery/DRA_7097-2_a6tc7p.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026830/Prewedding/gallery/thumbnail/DRA_7097-2_sgfwk1.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978176/Prewedding/gallery/DRA_7117_ec8vq5.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026854/Prewedding/gallery/thumbnail/DRA_7117_fe1kfo.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7127_gqmyiq.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026830/Prewedding/gallery/thumbnail/DRA_7127_rv8sh0.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978179/Prewedding/gallery/DRA_7131_jihmbs.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026854/Prewedding/gallery/thumbnail/DRA_7131_xfhejj.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978177/Prewedding/gallery/DRA_7158_e1bhca.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026854/Prewedding/gallery/thumbnail/DRA_7158_v8tpt3.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978161/Prewedding/gallery/DRA_7164_vvmkpg.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026830/Prewedding/gallery/thumbnail/DRA_7164_plxabl.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978177/Prewedding/gallery/DRA_7176_mhiypm.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026854/Prewedding/gallery/thumbnail/DRA_7176_lksjrb.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978162/Prewedding/gallery/DRA_7188_rxjtx5.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026830/Prewedding/gallery/thumbnail/DRA_7188_nudeur.jpg",
  },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978162/Prewedding/gallery/DRA_7192_e3slr5.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026830/Prewedding/gallery/thumbnail/DRA_7192_lmxl2j.jpg",
  // },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978163/Prewedding/gallery/DRA_7211_krzuka.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026831/Prewedding/gallery/thumbnail/DRA_7211_qlungz.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978178/Prewedding/gallery/DRA_7231_xlfyqs.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026855/Prewedding/gallery/thumbnail/DRA_7231_nafwab.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978163/Prewedding/gallery/DRA_7240_tvbg9p.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026831/Prewedding/gallery/thumbnail/DRA_7240_pz8wus.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_7253_wfjzh6.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026835/Prewedding/gallery/thumbnail/DRA_7253_rgk5tb.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_7265_ecqwrv.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026831/Prewedding/gallery/thumbnail/DRA_7265_mhsnem.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_7321_o5brdn.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026832/Prewedding/gallery/thumbnail/DRA_7321_t1jfpl.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_7329_cbyy2a.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026831/Prewedding/gallery/thumbnail/DRA_7329_vehtdy.jpg",
  },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978168/Prewedding/gallery/DRA_7367_mcryjj.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026832/Prewedding/gallery/thumbnail/DRA_7367_jgsjbx.jpg",
  // },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978165/Prewedding/gallery/DRA_7401_h2o08o.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026832/Prewedding/gallery/thumbnail/DRA_7401_a1c9bn.jpg",
  // },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978177/Prewedding/gallery/DRA_7414_qtvdf9.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026855/Prewedding/gallery/thumbnail/DRA_7414_pjbcva.jpg",
  // },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978177/Prewedding/gallery/DRA_7426_ezr4mi.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026855/Prewedding/gallery/thumbnail/DRA_7426_dftu9k.jpg",
  // },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978179/Prewedding/gallery/DRA_7454_vjoxsq.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026857/Prewedding/gallery/thumbnail/DRA_7454_hieegj.jpg",
  // },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978165/Prewedding/gallery/DRA_7483_hw2ocf.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026832/Prewedding/gallery/thumbnail/DRA_7483_hy9kqn.jpg",
  // },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978178/Prewedding/gallery/DRA_7488_am4azh.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026856/Prewedding/gallery/thumbnail/DRA_7488_ktyjx5.jpg",
  // },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978178/Prewedding/gallery/DRA_7502_ohbbqb.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026856/Prewedding/gallery/thumbnail/DRA_7502_h4dvrt.jpg",
  // },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978178/Prewedding/gallery/DRA_7521_bha4u1.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026856/Prewedding/gallery/thumbnail/DRA_7521_bq8awi.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978181/Prewedding/gallery/DRA_7583_tzsmyi.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026856/Prewedding/gallery/thumbnail/DRA_7583_eixvxx.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978168/Prewedding/gallery/DRA_7588_dosdue.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026832/Prewedding/gallery/thumbnail/DRA_7588_vantwy.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978179/Prewedding/gallery/DRA_7590_dfhsws.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026856/Prewedding/gallery/thumbnail/DRA_7590_m5u2x0.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7593_k1hajz.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026833/Prewedding/gallery/thumbnail/DRA_7593_ug0oit.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978165/Prewedding/gallery/DRA_7637_hw8nf4.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026833/Prewedding/gallery/thumbnail/DRA_7637_pabbyt.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978179/Prewedding/gallery/DRA_7642_ut19gc.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026856/Prewedding/gallery/thumbnail/DRA_7642_sqnk57.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978168/Prewedding/gallery/DRA_7644_wmfb2w.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026833/Prewedding/gallery/thumbnail/DRA_7644_npskkh.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978180/Prewedding/gallery/DRA_7656_wils6z.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026856/Prewedding/gallery/thumbnail/DRA_7656_blrlj0.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978168/Prewedding/gallery/DRA_7677_ygzjbe.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026833/Prewedding/gallery/thumbnail/DRA_7677_jxktvg.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978174/Prewedding/gallery/DRA_7702_bsk0va.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026833/Prewedding/gallery/thumbnail/DRA_7702_mp0rzv.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978169/Prewedding/gallery/DRA_7717_itne64.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026833/Prewedding/gallery/thumbnail/DRA_7717_u4dja6.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978170/Prewedding/gallery/DRA_7728_vkdiph.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026834/Prewedding/gallery/thumbnail/DRA_7728_vcsg4a.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978170/Prewedding/gallery/DRA_7736_wix1jz.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026834/Prewedding/gallery/thumbnail/DRA_7736_dloske.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978170/Prewedding/gallery/DRA_7750_fpycnr.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026834/Prewedding/gallery/thumbnail/DRA_7750_rb0l8k.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978183/Prewedding/gallery/DRA_7793_bhtuhw.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026857/Prewedding/gallery/thumbnail/DRA_7793_rsrk87.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7822_jcrhld.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026834/Prewedding/gallery/thumbnail/DRA_7822_wqaigb.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978181/Prewedding/gallery/DRA_7826_qveefw.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026856/Prewedding/gallery/thumbnail/DRA_7826_kqpkjd.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978180/Prewedding/gallery/DRA_7854_urcxbr.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026857/Prewedding/gallery/thumbnail/DRA_7854_ceqham.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978182/Prewedding/gallery/DRA_7861_mdjafq.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026857/Prewedding/gallery/thumbnail/DRA_7861_ljtw14.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978182/Prewedding/gallery/DRA_7868_a5rtd7.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026858/Prewedding/gallery/thumbnail/DRA_7868_cm5lcy.jpg",
  },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978181/Prewedding/gallery/DRA_7874_nmplxs.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026857/Prewedding/gallery/thumbnail/DRA_7874_lowk9r.jpg",
  // },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7883_c9hjkd.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026834/Prewedding/gallery/thumbnail/DRA_7883_jwaulq.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978182/Prewedding/gallery/DRA_7889_k3ykhv.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026857/Prewedding/gallery/thumbnail/DRA_7889_nehpgf.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978183/Prewedding/gallery/DRA_7894_p2q84l.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026857/Prewedding/gallery/thumbnail/DRA_7894_ljiayk.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978183/Prewedding/gallery/DRA_7901_fuubzy.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026858/Prewedding/gallery/thumbnail/DRA_7901_zyedtq.jpg",
  },
  // {
  //   full: "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_7905_dxtnnq.jpg",
  //   thumbnail:
  //     "https://res.cloudinary.com/setyawan/image/upload/v1624026858/Prewedding/gallery/thumbnail/DRA_7905_zz5hfs.jpg",
  // },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978183/Prewedding/gallery/DRA_7915_kpbvi9.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026858/Prewedding/gallery/thumbnail/DRA_7915_hvj3by.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978171/Prewedding/gallery/DRA_7922_paxgub.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026834/Prewedding/gallery/thumbnail/DRA_7922_bkrdhu.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978159/Prewedding/gallery/DRA_7931_dboxvf.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026835/Prewedding/gallery/thumbnail/DRA_7931_latbby.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978159/Prewedding/gallery/DRA_7945_blhegb.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026835/Prewedding/gallery/thumbnail/DRA_7945_p7doqa.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978159/Prewedding/gallery/DRA_7952_mgvukf.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026835/Prewedding/gallery/thumbnail/DRA_7952_n1nglj.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_7954_nll4d2.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026858/Prewedding/gallery/thumbnail/DRA_7954_qimpbd.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978159/Prewedding/gallery/DRA_7962_b95vzy.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026835/Prewedding/gallery/thumbnail/DRA_7962_evveum.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_7965_gkwwby.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026858/Prewedding/gallery/thumbnail/DRA_7965_vtsmtv.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978160/Prewedding/gallery/DRA_7988_ty01yb.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026835/Prewedding/gallery/thumbnail/DRA_7988_ivmpam.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978164/Prewedding/gallery/DRA_8004_t0iela.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026840/Prewedding/gallery/thumbnail/DRA_8004_ax0woj.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978186/Prewedding/gallery/DRA_8018_dysico.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026858/Prewedding/gallery/thumbnail/DRA_8018_kkmi1x.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_8019_p8hbjn.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026859/Prewedding/gallery/thumbnail/DRA_8019_i353zd.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_8022_yh7n5c.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026859/Prewedding/gallery/thumbnail/DRA_8022_v9gwp2.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978184/Prewedding/gallery/DRA_8030_m6vubk.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026859/Prewedding/gallery/thumbnail/DRA_8030_lfsvba.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8038_eof2td.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026859/Prewedding/gallery/thumbnail/DRA_8038_y91hbu.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8042_yc5xlg.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026859/Prewedding/gallery/thumbnail/DRA_8042_kzc7mr.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8056_ojhtap.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026859/Prewedding/gallery/thumbnail/DRA_8056_llljoa.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8065_jlqszn.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026859/Prewedding/gallery/thumbnail/DRA_8065_mrbqoe.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978185/Prewedding/gallery/DRA_8073_eut7cy.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026859/Prewedding/gallery/thumbnail/DRA_8073_sby2cp.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978186/Prewedding/gallery/DRA_8075_vn16gi.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026860/Prewedding/gallery/thumbnail/DRA_8075_a1vtt7.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978186/Prewedding/gallery/DRA_8079_zdtfvg.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026855/Prewedding/gallery/thumbnail/DRA_8079_t25ulh.jpg",
  },
  {
    full: "https://res.cloudinary.com/setyawan/image/upload/v1623978187/Prewedding/gallery/DRA_8082_zap21g.jpg",
    thumbnail:
      "https://res.cloudinary.com/setyawan/image/upload/v1624026854/Prewedding/gallery/thumbnail/DRA_8082_sa6bzf.jpg",
  },
];
