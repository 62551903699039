import Button from "@/components/Button.vue";

const LINK = "https://www.addevent.com/event/";

export default {
  name: "AddEvent",
  props: {
    eventId: {
      type: String,
      default: "",
    },
  },
  components: {
    Button,
  },
  computed: {
    isIOS() {
      return (
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        this.$browserDetect.isSafari
      );
    },
    url() {
      return this.isIOS
        ? `${LINK}${this.eventId}+apple`
        : `${LINK}${this.eventId}+google`;
    },
  },
  methods: {
    openCalendar() {
      this.$gtag.event("open_calendar", {
        method: this.isIOS ? "Apple Calendar" : "Google Calendar",
      });
      window.open(this.url, "_blank");
    },
  },
};
