import Vue from "vue";
import VueRouter from "vue-router";
import supabase from "@/utils/supabase";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/app",
    name: "App",
    component: () =>
      import(/* webpackChunkName: "application" */ "../views/App.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/qr",
    name: "QR",
    component: () =>
      import(/* webpackChunkName: "qr" */ "../views/Capture.vue"),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const user = await supabase.auth.user();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!user) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }

  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (user) {
      next({ path: "/app" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
