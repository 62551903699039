<template>
  <div class="gallery">
    <div
      v-masonry
      transition-duration="0.3s"
      item-selector=".image"
      column-width=".image"
    >
      <viewer
        :options="options"
        :images="images"
        @inited="inited"
        class="viewer"
        ref="viewer"
      >
        <template slot-scope="scope">
          <img
            class="image"
            v-masonry-tile
            v-for="{ full, thumbnail } in scope.images"
            :src="thumbnail"
            :data-source="full"
            :alt="full"
            :key="full"
          />
        </template>
      </viewer>
    </div>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer);

import images from "@/utils/images";

export default {
  name: "Gallery",
  data() {
    return {
      images,
      options: {
        inline: false,
        title: false,
        movable: false,
        scalable: false,
        url: "data-source",
      },
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  background-color: $primary-color;
  .image {
    width: 50%;
    cursor: pointer;
    @include device(desktop) {
      width: 20%;
    }
  }
}
</style>
