<template>
  <div class="rules">
    <div data-aos="zoom-out-up" data-aos-duration="1500">
      <inline-svg
        :src="require('@/assets/svg/leaf.svg')"
        aria-label="leaf"
        height="48"
        class="leaf"
      />
      <div class="title">Rules</div>
      <p class="text">
        Dikarenakan sedang dalam masa pandemi, tanpa mengurangi rasa hormat,
        diharapkan seluruh tamu dapat mengikuti protokol kesehatan yang
        dianjurkan oleh pemerintah.
      </p>
      <carousel
        :autoplay="true"
        :easing="'linear'"
        :loop="true"
        :paginationActiveColor="'#6A7D6A'"
        :paginationColor="'rgba(106, 125, 106, 0.2)'"
        :perPageCustom="[
          [320, 1],
          [1025, 4],
        ]"
        :speed="500"
        class="rule"
      >
        <slide>
          <div class="container">
            <div class="icon">
              <inline-svg
                :src="require('@/assets/svg/clock.svg')"
                aria-label="clock"
              />
            </div>
            <div>#1</div>
            <span
              >Mohon dapat hadir sesuai dengan waktu yang telah
              ditentukan.</span
            >
          </div>
        </slide>
        <slide>
          <div class="container">
            <div class="icon">
              <inline-svg
                :src="require('@/assets/svg/person.svg')"
                aria-label="person"
              />
            </div>
            <div>#2</div>
            <span>Undangan ini hanya berlaku untuk maksimal 2 orang.</span>
          </div>
        </slide>
        <slide>
          <div class="container">
            <div class="icon">
              <inline-svg
                :src="require('@/assets/svg/qr.svg')"
                aria-label="qr"
              />
            </div>
            <div>#3</div>
            <span>Tunjukkan QR Code di kartu undangan / E-invitation</span>
          </div>
        </slide>
        <slide>
          <div class="container container--primary">
            <div class="icon">
              <inline-svg
                :src="require('@/assets/svg/mask.svg')"
                aria-label="mask"
              />
            </div>
            <div>#4</div>
            <span>
              Wajib menggunakan masker dan menaati protokol kesehatan lainnya
              yang berlaku selama acara.
            </span>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "Rules",
  components: {
    Carousel,
    Slide,
  },
};
</script>

<style lang="scss" scoped>
.rules {
  padding: 3rem 1.5rem;
  @include background-leaf;
  @include device(desktop) {
    padding: 6rem 8rem;
  }
  &__info {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  .rule {
    margin-top: 2rem;
    @include device(desktop) {
      margin-top: 5rem;
    }
  }
  .container {
    border: 1px solid $secondary-color;
    border-radius: 8px;
    padding: 1.5rem;
    width: 60%;
    margin: auto;
    line-height: 1.5rem;
    &--primary {
      background-color: $primary-color;
      color: $secondary-color;
    }
    .icon {
      width: 48px;
      height: 48px;
      margin: auto;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
