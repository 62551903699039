<template>
  <div class="date" v-prlx.background="{ speed: 0.05 }" v-if="show">
    <div class="container" data-aos="zoom-out-up" data-aos-duration="1500">
      <div class="title">Save the Date</div>
      <Countdown :eventDate="eventDate" class="countdown" />
      <AddEvent event-id="BE6694939" class="button" />
    </div>
  </div>
</template>

<script>
import Countdown from "@/components/Countdown.vue";
import AddEvent from "@/components/AddEvent.vue";

export default {
  name: "Date",
  components: {
    Countdown,
    AddEvent,
  },
  data() {
    return {
      eventDate: new Date("2021-06-27T08:00:00.000+07:00"),
    };
  },
  computed: {
    show() {
      const remaining = this.eventDate - new Date();
      return remaining > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.date {
  background-image: url("https://res.cloudinary.com/setyawan/image/upload/v1623816623/Prewedding/date_m_coes7q.jpg");
  background-size: cover;
  background-position: bottom;
  @include background-blend;
  @include device(desktop) {
    background-image: url("https://res.cloudinary.com/setyawan/image/upload/v1623806117/Prewedding/date_suxrrn.jpg");
    background-size: auto;
  }
  .container {
    padding: 7rem 1.5rem;
    @include device(desktop) {
      padding: 6rem 8rem;
    }
    .countdown {
      margin: auto;
      @include device(desktop) {
        width: 40%;
      }
    }
    .button {
      margin-top: 1rem;
    }
  }
}
</style>
