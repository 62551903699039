import supabase from "@/utils/supabase";
import { isValidUUID } from "@/utils/uuid";
import { app } from "@/config";

export default {
  name: "SaveQR",
  props: {
    id: {
      type: String,
      default: "",
    },
    itemId: {
      type: String,
      default: "",
    },
  },
  methods: {
    async save() {
      const el = document.getElementById(this.itemId);
      const options = {
        type: "dataURL",
      };
      const dataUrl = await this.$html2canvas(el, options);
      this.saveAs(dataUrl, "e-invitation Friska & Agung Wedding.png");
      await this.saveQrDate();
    },
    saveAs(uri, filename) {
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },
    async saveQrDate() {
      try {
        if (!isValidUUID(this.id)) return;
        this.$gtag.event("save_qr");
        const { error } = await supabase
          .from(app.dbName)
          .update({ saveQrDate: new Date() })
          .eq("id", this.id);
        if (error) {
          this.$toasted.global.mwError({ message: error.message });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
