<template>
  <modal
    name="to"
    :adaptive="true"
    :width="'90%'"
    :height="'auto'"
    :maxWidth="780"
    :scrollable="true"
    :transition="'ease-in'"
    :clickToClose="false"
    v-scroll-lock="true"
  >
    <div class="modal">
      <div class="title">Pernikahan</div>
      <div class="name">Friska & Agung</div>
      <div class="text-medium">Minggu, 27 Juni 2021</div>
      <div class="to">
        <p class="text-medium">
          Kepada <br />
          <strong>{{ name }}</strong> <br />
          di tempat
        </p>
        <p class="text-medium">
          {{ sessionText }}
        </p>
        <SaveQR :id="id" item-id="capture" />
      </div>
      <Button type="primary" @click.native="hideModalTo()">
        Buka Undangan
      </Button>
    </div>
  </modal>
</template>

<script>
import supabase from "@/utils/supabase";
import { isValidUUID } from "@/utils/uuid";
import { app } from "@/config";
import Button from "@/components/Button.vue";
import SaveQR from "@/components/SaveQR.vue";

export default {
  name: "ModalTo",
  components: {
    Button,
    SaveQR,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    session: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    sessionText() {
      return this.session === 1
        ? "Sesi 1 Pukul 11.00 - 12.30 WIB."
        : "Sesi 2 Pukul 12.30 - 14.00 WIB.";
    },
  },
  methods: {
    async hideModalTo() {
      try {
        if (!isValidUUID(this.id)) return;
        this.$gtag.event("open_invitation");
        const { error } = await supabase
          .from(app.dbName)
          .update({ openInvitationDate: new Date() })
          .eq("id", this.id)
          .is("openInvitationDate", null);
        if (error) {
          this.$toasted.global.mwError({ message: error.message });
        } else {
          this.$modal.hide("to");
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  color: $primary-color;
  padding: 2rem 0;
  text-align: center;
  .name {
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
  .to {
    background-color: $primary-color;
    color: $secondary-color;
    padding: 1rem;
    margin: 2rem 0;
  }
}
</style>
