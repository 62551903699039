<template>
  <div class="hero" v-prlx.background="{ speed: 0.05 }">
    <div class="container">
      <div class="logo">
        <div class="date">Minggu, 27 Juni 2021</div>
        <inline-svg :src="require('@/assets/svg/main.svg')" aria-label="main" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url("https://res.cloudinary.com/setyawan/image/upload/v1623809161/Prewedding/hero_m_vpenp1.jpg");
  background-position: bottom;
  background-size: cover;
  @include background-blend;
  @include device(desktop) {
    background-image: url("https://res.cloudinary.com/setyawan/image/upload/v1623804907/Prewedding/hero_ltnhz3.jpg");
    background-size: auto;
  }
  .container {
    height: 100vh;
    .logo {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
      max-width: 550px;
      width: 80%;
      padding: 20px;
      @include device(desktop) {
        width: 40%;
      }
      .date {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        letter-spacing: 0.1em;
        color: #bbaf80;
        @include device(desktop) {
          font-size: 2.2rem;
        }
      }
    }
  }
}
</style>
