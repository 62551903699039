import supabase from "@/utils/supabase";
import { isValidUUID } from "@/utils/uuid";
import { app } from "@/config";

import AddEvent from "@/components/AddEvent.vue";
import ModalGift from "@/components/modal/Gift.vue";
import ModalNotification from "@/components/modal/Notification.vue";
import ModalTo from "@/components/modal/To.vue";
import Hero from "@/components/section/Hero.vue";
import Couple from "@/components/section/Couple.vue";
import Date from "@/components/section/Date.vue";
import Event from "@/components/section/Event.vue";
import Rules from "@/components/section/Rules.vue";
import CheckIn from "@/components/section/CheckIn.vue";
import Gallery from "@/components/section/Gallery.vue";
import Footer from "@/components/section/Footer.vue";

export default {
  name: "Home",
  components: {
    AddEvent,
    ModalGift,
    ModalNotification,
    ModalTo,
    Hero,
    Couple,
    Date,
    Event,
    Rules,
    CheckIn,
    Gallery,
    Footer,
  },
  data() {
    return {
      guestInfo: null,
    };
  },
  async mounted() {
    if (isValidUUID(this.id)) {
      await this.readGuest();
      if (this.guestInfo && !this.hasOpenedInvitation) {
        this.showModalTo();
      }
    }
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    guestName() {
      return this.guestInfo?.name ?? "";
    },
    guestSession() {
      return this.guestInfo?.session ?? 1;
    },
    guestSource() {
      return this.guestInfo?.source ?? "";
    },
    hasOpenedInvitation() {
      return !!this.guestInfo?.openInvitationDate;
    },
  },
  methods: {
    goTo(refName) {
      this.$smoothScroll({
        scrollTo: this.$refs[refName],
        duration: 1500,
      });
    },
    showModalTo() {
      this.$modal.show("to");
    },
    async readGuest() {
      try {
        const { data: guest, error } = await supabase
          .from(app.dbName)
          .select("*")
          .eq("id", this.id);
        if (error) {
          this.$toasted.global.mwError({ message: error.message });
        } else if (!guest?.length) {
          this.$toasted.global.mw({ message: "ID tidak ditemukan" });
        } else {
          this.guestInfo = guest[0];
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
