<template>
  <div class="check-in" v-if="name">
    <div class="container" data-aos="zoom-out-up" data-aos-duration="1500">
      <div id="capture" class="capture">
        <inline-svg
          :src="require('@/assets/svg/leaf-white.svg')"
          aria-label="leaf-white"
          height="48"
          class="leaf"
        />
        <div class="title">Check In</div>
        <p class="text-medium">Kepada {{ name }}</p>
        <div class="info">
          <div class="text">Dimohon untuk datang pada &nbsp;</div>
          <div class="text">
            <strong>{{ sessionText }}</strong>
          </div>
          <div class="text">
            <strong>Harap tunjukkan QR code ini di venue.</strong>
          </div>
        </div>
        <QR :value="id" />
      </div>
      <SaveQR :id="id" item-id="capture" />
      <p class="text">
        Konfirmasi kehadiranmu dalam acara pernikahan Friska & Agung
      </p>
      <div class="confirmation">
        <v-select
          :options="options"
          v-model="selected"
          placeholder="Konfirmasi Kehadiran"
          class="style-chooser"
        />
        <div class="action">
          <Button
            type="tertiary"
            icon="heart"
            v-if="person === 0"
            @click.native="showModalGift()"
          >
            Dompet Digital
          </Button>
          <Button
            type="secondary"
            icon="tick-square"
            :disabled="isDisabled"
            @click.native="confirmation()"
          >
            Konfirmasi
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import supabase from "@/utils/supabase";
import { isValidUUID } from "@/utils/uuid";
import { app } from "@/config";
import Button from "@/components/Button.vue";
import QR from "@/components/QR.vue";
import SaveQR from "@/components/SaveQR.vue";

export default {
  name: "CheckIn",
  components: {
    Button,
    QR,
    SaveQR,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    session: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      options: [
        { label: "Hadir 1 Orang", code: 1 },
        { label: "Hadir 2 Orang", code: 2 },
        { label: "Tidak Dapat Hadir", code: 0 },
      ],
      selected: null,
    };
  },
  computed: {
    person() {
      return this.selected?.code;
    },
    isDisabled() {
      return this.selected === null;
    },
    sessionText() {
      return this.session === 1
        ? "Sesi 1 Pukul 11.00 - 12.30 WIB."
        : "Sesi 2 Pukul 12.30 - 14.00 WIB.";
    },
  },
  methods: {
    showModalGift() {
      this.$modal.show("gift");
    },
    showModalNotification() {
      this.$modal.show("notification");
    },
    async confirmation() {
      try {
        if (!isValidUUID(this.id)) return;
        this.$gtag.event("click_confirmation");
        const { error } = await supabase
          .from(app.dbName)
          .update({ confirmationDate: new Date(), person: this.person })
          .eq("id", this.id);
        if (error) {
          this.$toasted.global.mwError({ message: error.message });
        } else {
          this.showModalNotification();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-in {
  background-color: $bg-color;
  padding: 0 1.5rem 3rem 1.5rem;
  @include device(desktop) {
    padding: 0 8rem 4rem 8rem;
  }
  .container {
    background-color: $primary-color;
    border-radius: 1.3rem;
    padding: 1.5rem;
    margin-top: -2rem;
    .capture {
      background-color: $primary-color;
      margin: auto;
      border-radius: 1.3rem;
      max-width: 600px;
      padding: 1rem 0;
    }
    .info {
      color: $primary-color;
      background-color: $tertiary-color;
      border-radius: 1.3rem;
      padding: 0.5rem;
      font-family: $additional-font;
      max-width: 540px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .confirmation {
      margin: auto;
      max-width: 380px;
      .action {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        @include device(desktop) {
          flex-direction: row;
        }
      }
    }
  }
}
</style>
