import Vue from "vue";
import VueGtag from "vue-gtag";
import browserDetect from "vue-browser-detect-plugin";
import { InlineSvgPlugin } from "vue-inline-svg";
import VuePrlx from "vue-prlx";
import VueSmoothScroll from "vue2-smooth-scroll";
import { VueMasonryPlugin } from "vue-masonry";
import VScrollLock from "v-scroll-lock";
import AOS from "aos";
import vSelect from "vue-select";
import VModal from "vue-js-modal";
import Toasted from "vue-toasted";
import VueHtml2Canvas from "vue-html2canvas";

import "aos/dist/aos.css";
import "vue-select/src/scss/vue-select.scss";

import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;
Vue.use(VueGtag, { config: { id: "G-RENWJDBM56" } }, router);
Vue.use(browserDetect);
Vue.use(InlineSvgPlugin);
Vue.use(VuePrlx);
Vue.use(VueSmoothScroll, { updateHistory: false });
Vue.use(VueMasonryPlugin);
Vue.use(VScrollLock);
Vue.use(VModal);
Vue.use(Toasted);
Vue.use(VueHtml2Canvas);

const toastOptions = {
  position: "bottom-center",
  duration: 5000,
};

Vue.toasted.register(
  "mw",
  (payload) => {
    if (!payload.message) return "Hello!";
    return payload.message;
  },
  toastOptions
);

Vue.toasted.register(
  "mwSuccess",
  (payload) => {
    if (!payload.message) return "Nice!";
    return payload.message;
  },
  { type: "success", ...toastOptions }
);

Vue.toasted.register(
  "mwError",
  (payload) => {
    if (!payload.message) return "Oops.. Something Went Wrong..";
    return payload.message;
  },
  { type: "error", ...toastOptions }
);

Vue.toasted.register(
  "mwInfo",
  (payload) => {
    if (!payload.message) return "Hi!";
    return payload.message;
  },
  { type: "info", ...toastOptions }
);

Vue.component("v-select", vSelect);

new Vue({
  router,
  created() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
