<template>
  <div class="footer">
    <div class="container">
      <div class="title" @click="unOpenInvitation()">Thank You</div>
      <div class="name">Friska &amp; Agung</div>
    </div>
  </div>
</template>

<script>
import supabase from "@/utils/supabase";
import { isValidUUID } from "@/utils/uuid";
import { app } from "@/config";

export default {
  name: "Footer",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  methods: {
    async unOpenInvitation() {
      try {
        if (!isValidUUID(this.id)) return;
        const { error } = await supabase
          .from(app.dbName)
          .update({ openInvitationDate: null })
          .eq("id", this.id)
          .not("openInvitationDate", "is", null);
        if (error) {
          this.$toasted.global.mwError({ message: error.message });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-image: url("https://res.cloudinary.com/setyawan/image/upload/v1623814309/Prewedding/footer_m_nhzujp.jpg");
  background-size: cover;
  background-position: center;
  @include background-blend;
  @include device(desktop) {
    background-image: url("https://res.cloudinary.com/setyawan/image/upload/v1623808118/Prewedding/footer_fpwhq4.jpg");
    background-size: auto;
  }
  .container {
    padding: 11.5rem 1.5rem;
    @include device(desktop) {
      padding: 9rem 8rem;
    }
    .name {
      font-size: 1.5rem;
    }
  }
}
</style>
