import VueCountdown from "@chenfengyuan/vue-countdown";
import { VNumber } from "@maxflex/v-number";

export default {
  name: "Countdown",
  components: {
    VueCountdown,
    VNumber,
  },
  props: {
    eventDate: {
      type: Date,
      default: () => new Date("2022-01-01T00:00:00.000+07:00"),
    },
  },
  computed: {
    timeRemaining() {
      const remaining = this.eventDate - new Date();
      return remaining > 0 ? remaining : 0;
    },
  },
};
