<template>
  <div class="couple">
    <div class="container" data-aos="zoom-out-up" data-aos-duration="1500">
      <div class="greeting">Assalamu’alaikum Warahmatullaahi Wabarakaatuh</div>
      <p class="text">
        Dengan memohon ridho dan rahmat Allah SWT. Kami bermaksud
        menyelenggarakan resepsi pernikahan kami,
      </p>
      <div class="name">Friska Devina, S.Ds</div>
      <span class="text">
        Putri dari Bapak Aep Saepudin (Alm) dan Ibu Lia Mulyanah
      </span>
      <p class="with">dengan</p>
      <div class="name">Agung Setyawan, S.Kom</div>
      <span class="text">
        Putra dari Bapak Toniman Muharam dan Ibu Euis Widatiningsih, S.E
      </span>
      <div class="cp">
        <img src="../../assets/images/cpw.jpg" alt="cpw" />
        <inline-svg
          :src="require('@/assets/svg/leaf.svg')"
          aria-label="leaf"
          height="48"
          class="leaf"
        />
        <img src="../../assets/images/cpp.jpg" alt="cpp" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Couple",
};
</script>

<style lang="scss" scoped>
.couple {
  display: flex;
  align-items: center;
  @include background-leaf;
  @include device(desktop) {
    height: 100vh;
  }
  .container {
    width: 100%;
    padding: 3rem 1.5rem;
    @include device(desktop) {
      padding: 0 8rem;
    }
    .greeting {
      font-weight: 600;
      margin-top: 1rem;
      line-height: 1.5rem;
      @include device(desktop) {
        line-height: 1.8rem;
        font-size: 1.5rem;
      }
    }
    .name {
      font-family: $secondary-font;
      font-size: 1.5rem;
      font-weight: 400;
      @include device(desktop) {
        font-size: 3rem;
        margin-top: 1rem;
      }
    }
    .with {
      letter-spacing: 1em;
      font-weight: 600;
      margin: 1.5rem 0;
      @include device(desktop) {
        margin: 1rem 0;
      }
    }
    .cp {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 1rem;
      @include device(desktop) {
        width: 60%;
        margin: 2rem auto 0 auto;
      }
      img {
        border-radius: 100%;
        width: 100px;
        @include device(desktop) {
          width: 190px;
        }
      }
    }
  }
}
</style>
