import QRCodeStyling from "qr-code-styling";

export default {
  name: "QR",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    const options = {
      width: 200,
      height: 200,
      type: "svg",
      data: this.value,
      image: "/icon.png",
      margin: 1,
      qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
      imageOptions: {
        hideBackgroundDots: false,
        crossOrigin: "anonymous",
      },
      dotsOptions: {
        color: "#6a7d6a",
        type: "dots",
      },
      backgroundOptions: {
        color: "#f4efec",
      },
      cornersSquareOptions: {
        color: "#6a7d6a",
        type: "extra-rounded",
      },
      cornersDotOptions: {
        color: "#6a7d6a",
        type: "dot",
      },
    };
    return {
      options,
      extension: "svg",
      qrCode: new QRCodeStyling(options),
    };
  },
  mounted() {
    this.qrCode.append(this.$refs["qrCode"]);
  },
};
