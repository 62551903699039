<template>
  <div class="event">
    <div data-aos="zoom-out-up" data-aos-duration="1500">
      <div class="title">Event</div>
      <p class="text">
        Dengan memohon rahmat dan ridha Allah Subhanahu Wa Ta'ala, izinkan kami
        mengundang Bapak/Ibu/Saudara(i) untuk menghadiri acara pernikahan kami.
      </p>
      <div class="container">
        <div class="event__detail">
          <div class="event__detail-header">
            <div class="event__detail-header-icon">
              <inline-svg
                :src="require('@/assets/svg/leaf2-white.svg')"
                height="48"
                aria-label="leaf2-white"
                class="leaf"
              />
              <div class="date">
                <div class="line-1">JUNI</div>
                <div class="line-2">27</div>
              </div>
              <inline-svg
                :src="require('@/assets/svg/leaf2-white.svg')"
                height="48"
                aria-label="leaf2-white"
                class="leaf leaf--mirror"
              />
            </div>
            <div class="event__detail-header-label">MINGGU 2021</div>
          </div>
          <div class="event__detail-date">
            <div class="agenda">
              <div class="agenda-title">Akad Nikah</div>
              <div class="agenda-info">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/svg/calendar-light.svg')"
                    aria-label="calendar-light"
                    height="24"
                  />
                </div>
                <span>Minggu, 27 Juni 2021</span>
              </div>
              <div class="agenda-info">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/svg/clock-light.svg')"
                    aria-label="clock-light"
                    height="24"
                  />
                </div>
                <span>08.00 WIB</span>
              </div>
            </div>
            <div class="agenda">
              <div class="agenda-title">Resepsi</div>
              <div class="agenda-info">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/svg/calendar-light.svg')"
                    aria-label="calendar-light"
                    height="24"
                  />
                </div>
                <span>Minggu, 27 Juni 2021</span>
              </div>
              <div class="agenda-info">
                <div class="icon">
                  <inline-svg
                    :src="require('@/assets/svg/clock-light.svg')"
                    aria-label="clock-light"
                    height="24"
                  />
                </div>
                <span>
                  <div>Sesi 1 11.00 - 12.30 WIB</div>
                  <div>Sesi 2 12.30 - 14.00 WIB</div>
                </span>
              </div>
            </div>
          </div>
          <div class="event__detail-location">
            <inline-svg
              :src="require('@/assets/svg/leaf-white.svg')"
              aria-label="leaf-white"
              height="48"
            />
            <div class="place">KIRANA GARDEN</div>
            <div class="address">
              Jl. Cigadung Raya Tengah No.24, Cigadung, Kec. Cibeunying Kaler,
              Bandung
            </div>
            <Button
              type="secondary"
              icon="maps"
              @click.native="
                openMap(
                  'https://www.google.com/maps/place/Kirana+Garden/@-6.883997,107.629622,16z/data=!4m5!3m4!1s0x0:0xf4012cc2e20733c0!8m2!3d-6.8839535!4d107.6294705?hl=en'
                )
              "
            >
              Buka di Google Maps
            </Button>
          </div>
        </div>
        <div class="event__detail">
          <div class="event__detail-maps">
            <GoogleMaps
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.0551528459996!2d107.62743361472033!3d-6.883997095025574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e7071c2e1641%3A0xf4012cc2e20733c0!2sKirana%20Garden!5e0!3m2!1sen!2sid!4v1621629051982!5m2!1sen!2sid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import GoogleMaps from "@/components/GoogleMaps.vue";

export default {
  name: "Event",
  components: {
    Button,
    GoogleMaps,
  },
  methods: {
    openMap(url) {
      this.$gtag.event("open_map");
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  background-color: $primary-color;
  color: $secondary-color;
  padding: 3rem 1.5rem;
  @include device(desktop) {
    padding: 6rem 8rem;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include device(desktop) {
      flex-direction: row;
    }
  }
  &__detail {
    flex: 0 0 100%;
    flex-direction: column;
    @include device(desktop) {
      flex: 0 0 calc(50% - 1rem);
      flex-direction: row;
    }
    &-header {
      max-width: 180px;
      margin: auto;
      @include device(desktop) {
        max-width: 200px;
      }
      &-icon {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
        .leaf {
          height: 70px;
        }
        .line-1 {
          font-size: 1.5rem;
          @include device(desktop) {
            font-size: 2rem;
          }
        }
        .line-2 {
          font-size: 2.5rem;
          font-weight: 600;
        }
      }
      &-label {
        font-size: 1.5rem;
      }
    }
    &-date {
      background: #758675;
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      padding: 2.1rem;
      display: flex;
      flex-flow: row wrap;
      margin: 1.5rem 0;
      .agenda {
        text-align: left;
        flex: 0 0 100%;
        margin-bottom: 1rem;
        @include device(desktop) {
          flex: 0 0 50%;
        }
        &-title {
          margin-bottom: 1rem;
          font-weight: 700;
        }
        &-info {
          display: inline-flex;
          align-items: flex-start;
          margin-bottom: 0.5rem;
          width: 100%;
          font-size: 0.9rem;
          align-items: center;
          .icon {
            margin-right: 0.5rem;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-location {
      .place {
        font-weight: 700;
        line-height: 1.5rem;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
      }
      .address {
        font-size: 0.9rem;
        line-height: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
    &-maps {
      background: #758675;
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      padding: 1rem;
      margin-top: 1.5rem;
    }
  }
}
</style>
