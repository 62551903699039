export default {
  name: "GoogleMaps",
  props: {
    src: {
      type: String,
      default:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.0551528459996!2d107.62743361472033!3d-6.883997095025574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e7071c2e1641%3A0xf4012cc2e20733c0!2sKirana%20Garden!5e0!3m2!1sen!2sid!4v1621629051982!5m2!1sen!2sid",
    },
  },
};
