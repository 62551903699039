<template>
  <modal
    name="notification"
    :adaptive="true"
    :width="'90%'"
    :height="'auto'"
    :maxWidth="780"
    :scrollable="true"
    :transition="'ease-in'"
    v-scroll-lock="true"
  >
    <div class="modal">
      <div class="title">Notifikasi</div>
      <inline-svg
        :src="require('@/assets/svg/heart-big.svg')"
        width="74"
        aria-label="heart-big"
        class="heart"
      />
      <p class="text">
        Terima kasih telah mengisi konfirmasi kehadiran
        <strong>{{ name }}</strong>
      </p>
      <Button type="primary" @click.native="$modal.hide('notification')">
        Kembali
      </Button>
    </div>
  </modal>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "ModalNotification",
  components: {
    Button,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  color: $primary-color;
  padding: 2rem;
  text-align: center;
  .heart {
    @include device(desktop) {
      width: 106px;
    }
  }
}
</style>
